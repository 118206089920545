import { useIntl as originalUseIntl } from "gatsby-plugin-react-intl"
import { MessageDescriptor } from "@formatjs/intl/src/types"
import { FormatXMLElementFn, PrimitiveType } from "intl-messageformat"
import { Options as IntlMessageFormatOptions } from "intl-messageformat/src/core"

/**
 * We need to use our own `formatMessage` to avoid @formatjs/intl to log error messages to console, as GatsbyJS itself
 * registers its own error handler. The problem is, that GatsbyJS' error handler cannot deal with error messages other than
 * a string. react-intl instead logs an Error-object. So we add `id` as `defaultMessage` if it isn't set explicitly.
 *
 * @see https://github.com/gatsbyjs/gatsby/blob/master/packages/gatsby/cache-dir/head/head-export-handler-for-browser.js#L67
 */
const useIntl = () => {
  const intlShape = originalUseIntl()

  if (process.env.BUILD_STAGE !== `develop`) {
    return intlShape
  }

  return {
    ...intlShape,
    formatMessage: (
      { id, defaultMessage, ...rest }: MessageDescriptor,
      values?: Record<
        string,
        PrimitiveType | FormatXMLElementFn<string, string>
      >,
      opts?: IntlMessageFormatOptions
    ) =>
      intlShape.formatMessage(
        {
          ...rest,
          id,
          defaultMessage: defaultMessage || id,
        },
        values,
        opts
      ),
  }
}

export default useIntl

module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Precisis EASEE®","short_name":"Precisis EASEE®","start_url":"/","lang":"de","background_color":"#001315","theme_color":"#001315","display":"minimal-ui","icon":"src/svg/inline/favicon.svg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"e18155efeb62d590f6baf546bfefaaae"},
    },{
      plugin: require('../node_modules/gatsby-plugin-smoothscroll/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-intl/gatsby-browser.js'),
      options: {"plugins":[],"path":"/builds/techmynder/clients/precisis/easee-website/src/i18n","redirectComponent":"/builds/techmynder/clients/precisis/easee-website/src/components/redirect.tsx","languages":["de","en"],"defaultLanguage":"de","fallbackLanguage":"en","redirect":true,"redirectDefaultLanguageToRoot":false,"ignoredPaths":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]

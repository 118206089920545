import React from "react"
import SEO from "~/components/seo"
import useIntl from "~/hooks/use-intl"

const Redirect = () => {
  const intl = useIntl()

  return <SEO title={intl.formatMessage({ id: "page.index.title" })} />
}

export default Redirect

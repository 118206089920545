import "~/css/index.css"

const onPreRouteUpdate = ({ prevLocation }) => {
  if (prevLocation) {
    document.documentElement.classList.toggle(
      "scroll-smooth",
      prevLocation.hash !== ""
    )
    document.documentElement.classList.toggle(
      "scroll-auto",
      prevLocation.hash === ""
    )
  }
}

export { onPreRouteUpdate }

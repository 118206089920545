import React, { useEffect, useState } from "react"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import { globalHistory } from "@reach/router"
import openGraphImage from "~/images/og-image.jpg"
import useIntl from "~/hooks/use-intl"

type MetaItem = {
  name: string
  content: string
}

type Props = {
  title: string | string[]
  description?: string
  meta?: MetaItem[]
  lang?: string
}

const SEO: React.FC<Props> = ({ description = "", lang, meta = [], title }) => {
  const [pathname, setPathname] = useState<string | null>()
  const [link, setLink] = useState<any>([])

  const { site } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          siteUrl
          title
          i18n {
            languages
          }
        }
      }
    }
  `)

  const intl = useIntl()
  const metaDescription =
    description || intl.formatMessage({ id: "meta.description" })

  // Listen for route changes to update current pathname in state
  useEffect(() => {
    // Set pathname on component mount
    setPathname(location.pathname)

    const removeListener = globalHistory.listen(({ action, location }) => {
      if (action === "POP") {
        return
      }

      setPathname(location.pathname)
    })

    return () => {
      removeListener()
    }
  }, [])

  // If pathname changes we update all alternate-hrefs via Helmet
  useEffect(() => {
    if (!pathname) {
      return
    }

    site.siteMetadata.i18n.languages.forEach((language: string) => {
      if (language === intl.locale) {
        return
      }

      setLink([
        {
          rel: `alternate`,
          href: `${site.siteMetadata.siteUrl}${pathname.replace(
            new RegExp(`^/${intl.locale}`),
            `/${language}`
          )}`,
          hrefLang: language,
        },
      ])
    })
  }, [pathname])

  title = typeof title === "string" ? title : title.join(" | ")

  return (
    <Helmet
      htmlAttributes={{
        lang: lang || intl.locale,
      }}
      title={title}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
      link={link}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: `${title} | ${site.siteMetadata.title}`,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:image`,
          content: openGraphImage,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ].concat(meta)}
    />
  )
}

export default SEO
